<template>
  <v-card
    v-model="showDialog"
    width="380"
     elevation="5"
    class="session-status-card position-absolute rounded-xl top-70vh z-index-3000"
    :class="route.fullPath == '/' ? 'mr-8' : 'mr-n7vw'"
  >
    <v-spacer>
      <v-btn
        class="position-absolute left-0"
        variant="text"
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-spacer>
    <v-card-title class="text-md mt-4 text-black-color">
      {{ notificationData.type === 'direct-session-request' ? 'جلسة مباشرة' : 'جلسة مجانية' }}
    </v-card-title>
    <div
      v-if="notificationData.status === 'rejected'"
      class="text-center d-flex flex-column align-center px-4 pb-6"
    >
      <v-btn
        size="small"
        color="#ffeff0"
        variant="flat"
        class="text-red font-weight-bold mt-3"
      >
        <span class="text-red">عذراً المرشد غير متاح الآن</span>
      </v-btn>
    </div>
    <div v-else-if="notificationData.status === 'accepted' || notificationData.status === 'paid'" class="px-4 pb-4">
      <p class="text-xs text-black-color" v-html="notificationData.message">
      </p>

      <div v-if="notificationData.type === 'direct-session-request'" class="mt-4 d-flex justify-space-between">
        <v-btn
          class="mt-4 text-xs"
          size="large"
          rounded="lg"
          width="48%"
          @click="onCheckout"
        >
          إتمام الدفع
        </v-btn>
        <v-btn
          class="mt-4 text-xs"
          size="large"
          rounded="lg"
          variant="outlined"
          width="48%"
          @click="navigateTo('/orders')"
        >
          الانتقال إلى الطلبات
        </v-btn>
      </div>
      <div v-else class="mt-4">
        <v-btn
          class="mt-4 text-xs"
          size="large"
          width="100%"
          @click="sessionStore.sendLog('ENTERED'); navigateTo('/room')"
        >
          الدخول إلى الجلسة
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { useAuthStore } from '~~/stores/AuthStore';
import { useCoachStore } from '~~/stores/CoachStore';
import { useSessionStore } from '~~/stores/SessionStore';

const coachStore = useCoachStore();
const authStore = useAuthStore();
const route = useRoute();
const emit = defineEmits(['close']);

const sessionStore = useSessionStore();

const notificationData = computed(() => {
  return authStore.pushNotificationData;
});

const showDialog = ref(false);

authStore.sessionTimer = null;
showDialog.value = true;

function onCheckout() {
  showDialog.value = false;
  emit('close');
  navigateTo('/checkout');
}

function closeDialog() {
  showDialog.value = false;
  emit('close');
}
</script>
<style>
.top-70vh {
  top: 70vh;
}
.mr-n7vw {
  margin-right: -7vw;
}
.z-index-3000 {
  z-index: 3000;
}
@media (max-width: 400px) {
  .session-status-card {
    width: 80% !important;
    margin-right: auto;
  }
}
</style>
